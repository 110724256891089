import { Injectable } from '@angular/core'
import { SwPush } from '@angular/service-worker'
import * as Sentry from '@sentry/angular'

@Injectable({
  providedIn: 'root',
})
export class PushService {
  private readonly VAPID_PUBLIC_KEY =
    'BEN8gjX6WMRR2sNFdYIt4S-G9EgFNK83YagfpBr6IiPAgggFJJpCFn7QWgJJgKG_bW9AVaH0On32tkSAwuHzSyA'

  constructor(private swPush: SwPush) {}

  get webPushIsAvailable(): boolean {
    Sentry.addBreadcrumb({
      category: "swpush",
      message: `Is enabled: ${this.swPush.isEnabled}`,
      level: Sentry.Severity.Info,
    });
    return this.swPush.isEnabled
  }

  public async requestWebPushSubscription(): Promise<string> {
    let sub: string = null
    try {
      sub = JSON.stringify(
        await this.swPush.requestSubscription({
          serverPublicKey: this.VAPID_PUBLIC_KEY,
        })
      )
    } catch (error) {
      Sentry.addBreadcrumb({
        category: 'swpush',
        message: `Is enabled: ${this.swPush.isEnabled}`,
        level: Sentry.Severity.Info,
      })
      Sentry.addBreadcrumb({
        category: 'exception',
        message: error.toString(),
        level: Sentry.Severity.Error,
      })
      Sentry.captureMessage(
        "Failed to request push subscription when it's reported to be available"
      )
    }

    return sub
  }
}
