import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Timestamp } from '@angular/fire/firestore'
import { Storage } from '@capacitor/storage'
import { Observable, ReplaySubject } from 'rxjs'
import { take } from 'rxjs/operators'

export interface User {
  uid: string
  email: string
  isAdmin: boolean
  name?: string
  isProfileComplete?: boolean
  pushSubscription?: string
  pushSubscriptionRequestDate?: string
  lastActive?: Timestamp
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly user: Observable<User | null>
  private userSubject: ReplaySubject<User | null> = new ReplaySubject(1)

  constructor(private auth: AngularFireAuth, private store: AngularFirestore) {
    this.user = this.userSubject.asObservable()
    this.auth.user.subscribe(async (authUser) => {
      let user: User = null
      if (authUser !== null) {
        const { value } = await Storage.get({ key: 'user' })
        if (value !== null) {
          user = JSON.parse(value)
        } else {
          const isAdmin =
            (await authUser.getIdTokenResult()).claims.admin === true
          user = { uid: authUser.uid, isAdmin: isAdmin, email: authUser.email }
          if (!isAdmin) {
            const snapshot = await this.store
              .doc<User>(`users/${authUser.uid}`)
              .get()
              .toPromise()
            user.isProfileComplete = snapshot.exists
            if (snapshot.exists) {
              const data = snapshot.data()
              user.name = data.name
            }
          }
          await Storage.set({ key: 'user', value: JSON.stringify(user) })
        }
      } else {
        await Storage.remove({ key: 'user' })
      }

      this.userSubject.next(user)
    })
  }

  public async updateUser(user: User) {
    await Storage.set({ key: 'user', value: JSON.stringify(user) })
    this.userSubject.next(user)
  }

  public async markUserActive() {
    let u = await this.user.pipe(take(1)).toPromise()
    await this.store
      .doc<User>(`users/${u.uid}`)
      .update({ lastActive: Timestamp.now() })
  }
}
