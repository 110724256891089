import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
import * as Sentry from "@sentry/angular";
import { environment } from 'src/environments/environment';
import { User, UserService } from './services/user.service';
import { User as UserModel } from 'src/app/firebase-models';
import { PushService } from './services/push.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent  implements OnInit {
  public user: User
  public appPages = []
  public appVersion = environment.appVersion

  constructor(private userService: UserService, private updates: SwUpdate,
    private alertController: AlertController, private pushService: PushService,
    private store: AngularFirestore) {
  }

  ngOnInit(): void {
    this.userService.user.subscribe(async (user) => {
      this.addUserContextToSentry(user);
      this.createMenu(user);
      await this.requestSubscriptionUpdate(user);
    });

    this.updates.available.subscribe(event => {
      this.alertController.create({
        header: "Alkalmazás frissítés",
        message: "Az alkalmazás új verziója érhető el. Nyomd meg az OK-t a frissítéshez!",
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.updates.activateUpdate().then(() => document.location.reload())
            }
          },
        ]
      }).then((alert) => alert.present())
    });
  }

  async requestSubscriptionUpdate(user: User) {
    if (user && user.pushSubscription) {
      const newSubscription = await this.pushService.requestWebPushSubscription()

      if (newSubscription !== user.pushSubscription) {
        Sentry.setContext("pushSubscription", {
          lastRequestDate: user.pushSubscriptionRequestDate || "unknown",
          oldSubscription: user.pushSubscription,
          newSubscription: newSubscription
        })
        Sentry.captureMessage("Push subscription has changed")
        user.pushSubscription = newSubscription
        await this.store.doc<UserModel>(`users/${user.uid}`).update({pushSubscription: newSubscription})
        await this.userService.updateUser(user)
      }
    }
  }
  
  addUserContextToSentry(user: User) {
    if (user != null) {
      Sentry.setUser({ email: user.email, id: user.uid, isAdmin: user.isAdmin });
    } else {
      Sentry.setUser(null);
    }
  }

  createMenu(user: User): void {
    const appPages = []

    if (user !== null) {
      if (user.isAdmin) {
        appPages.push({ title: 'Bérletek', url: '/admin/passes', icon: 'card' })
        appPages.push({ title: 'Bérlet típusok', url: '/admin/passtypes', icon: 'list' })
        appPages.push({ title: 'Felhasználók', url: '/admin/users', icon: 'people' })
        appPages.push({ title: 'Gyakorlatok', url: '/admin/excercises', icon: 'barbell' })
      } else {
        appPages.push({ title: 'Bérleteim', url: '/client/mypasses', icon: 'card' })
        appPages.push({ title: 'Teljesítményeim', url: '/client/achievements', icon: 'trophy' })
        appPages.push({ title: 'Profilom', url: '/client/profile', icon: 'person-circle' })
      }
      
      this.user = user;
      appPages.push({ title: 'Kijelentkezés', url: '/logout', icon: 'log-out' })
    } else {
      this.user = null;
      appPages.push({ title: 'Bejelentkezés', url: '/login', icon: 'log-in' })
    }
    
    this.appPages = appPages
  }
}
