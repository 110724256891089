import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, hasCustomClaim, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const redirectLoggedInToRoot = () => redirectLoggedInTo(['/']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const adminOnly = () => hasCustomClaim('admin')


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'admin/passtypes',
    loadChildren: () => import('./pages/admin/passtypes/passtypes.module').then(m => m.PasstypesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly }
  },
  {
    path: 'admin/passes',
    loadChildren: () => import('./pages/admin/passes/passes.module').then(m => m.PassesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly }
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./pages/admin/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly }
  },
  {
    path: 'admin/user-passes',
    loadChildren: () => import('./pages/admin/user-passes/user-passes.module').then( m => m.UserPassesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly }
  },
  {
    path: 'admin/excercises',
    loadChildren: () => import('./pages/admin/excercises/excercises.module').then( m => m.ExcercisesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: adminOnly }
  },
  {
    path: 'client/profile',
    loadChildren: () => import('./pages/client/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client/mypasses',
    loadChildren: () => import('./pages/client/mypasses/mypasses.module').then(m => m.MypassesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client/requestpass',
    loadChildren: () => import('./pages/client/requestpass/requestpass.module').then(m => m.RequestpassPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client/achievements',
    loadChildren: () => import('./pages/client/achievements/achievements.module').then( m => m.AchievementsPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client/achievement-history/:excerciseId',
    loadChildren: () => import('./pages/client/achievement-history/achievement-history.module').then( m => m.AchievementHistoryPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToRoot }
  },
  {
    path: 'login-callback',
    loadChildren: () => import('./pages/login-callback/login-callback.module').then( m => m.LoginCallbackPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
