export const environment = {
  production: true,
  sentryEnvironment: "production",
  firebase: {
    apiKey: "AIzaSyB90bC3j3w7Yc4Z7OuM5BDX4W0AkiMfmBA",
    authDomain: "green-bunny-production.firebaseapp.com",
    projectId: "green-bunny-production",
    storageBucket: "green-bunny-production.appspot.com",
    messagingSenderId: "108775776329",
    appId: "1:108775776329:web:564a04a773431cd38ddf98"
  },
  appVersion: '1.1.41',
  useEmulators: false
};
