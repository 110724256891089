<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <div *ngIf="user">
            <ion-list-header *ngIf="user.isAdmin">Adminisztrátor</ion-list-header>
            <ion-list-header *ngIf="!user.isAdmin && user.isProfileComplete">Üdv, {{ user.name }}!</ion-list-header>
            <ion-note>{{ user.email }}</ion-note>
          </div>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-item lines="none"><ion-label color="medium">Verzió: {{ appVersion }}</ion-label></ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
