import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import * as Sentry from '@sentry/angular'
import { Integrations } from '@sentry/tracing'

if (environment.production) {
  Sentry.init({
    dsn: 'https://f4f647fecc494faaa01145e59f5a336f@o986867.ingest.sentry.io/5943627',
    environment: environment.sentryEnvironment,
    release: `greenbunnyapp@${environment.appVersion}`,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'green-bunny-test.web.app',
          'app.greenbunnyfmt.hu',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    ignoreErrors: [
      'ChunkLoadError',
      'Failed to obtain exclusive access to the persistence layer.',
      'FetchEvent.respondWith received an error',
    ],
  })

  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err))
